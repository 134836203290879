
import Vue from "vue";

export default Vue.extend({
  props: {
    name: {
      type: String,
      default: null,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    cssVariant: {
      type: String,
      default: null,
    },
    value: {
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: false,
    };
  },
  computed: {
    radioButtonValue: {
      get: function (): any {
        return this.value;
      },
      set: function (): any {
        this.$emit("input", this.id);
      },
    },
    inputClasses(): string[] {
      return this.createClassesArray("inpt");
    },
  },
  methods: {
    createClassesArray(classesPrefix: string) {
      let classesArray: string[] = [classesPrefix];

      let variant: string = this.cssVariant;
      let type: string = "radio";
      let required: string = this.required.toString();

      variant = variant
        ? `${classesPrefix}--${variant}`
        : `${classesPrefix}--default`;
      type = type ? `${classesPrefix}--${type}` : `${classesPrefix}--text`;
      required = this.required ? `${classesPrefix}--required` : "";

      classesArray = [...classesArray, variant, type, required];
      return classesArray;
    },
    autoSave() {
      this.$nextTick(function () {
        this.$emit("auto-save");
      });
    },
  },
});
